@import 'npm:tiny-slider/dist/tiny-slider.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

:target {
  scroll-margin-top: 100px;
}

.bg-media::before {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.2;
  background-color: white;
}

.about #header {
  background-color: #1a1a27;
}

.tabbed .tabs .tab {
  @apply h-[75px] px-5 rounded-t cursor-pointer flex items-center justify-center;
  flex: 1;
}
.tabbed .tabs .tab.active {
  background-color: #68689933;
}
.tabbed .tab-content {
  @apply px-6 py-6 rounded mb-11;
  background-color: #68689933;
  display: none;
  min-height: 286px;
}
.tabbed .tab-content.active {
  display: block;
}
